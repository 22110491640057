import EventService from '@/services/EventService.js'

export const namespaced = true

export const state = {
    customer: {
        kundenId: ''
    },
    events: []
}

export const mutations = {
    SET_EVENTS(state, events) {
        state.events = events
    },
    SET_CUSTOMER(state, customer) {
        state.customer = customer
    }
}

export const actions = {
    fetchEvents({ commit }, config ) {
        return EventService.getEvents(config.kundenId, config.token)
            .then((response) => {
                commit('SET_EVENTS', response.data.events)
                commit('SET_CUSTOMER', {
                    keycloakId: response.data.id,
                    kundenId: config.kundenId,
                    realm: response.data.realm,
                    lastLogin: response.data.lastLogin,
                    lastFailedLogin: response.data.lastFailedLogin
                })
            })
            .catch((error) => {
                console.log("error fetching events", error)
            })
    }
}
