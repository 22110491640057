<template>
  <div class="customer">
    <div>
      <div>kundenId={{ customer.kundenId }}</div>
      <div>keycloakId={{ customer.keycloakId }}</div>
    </div>
    <div>
      <div>lastLogin={{ customer.lastLogin }}</div>
      <div>lastFailedLogin={{ customer.lastFailedLogin }}</div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.customer {
  display: flex;
  justify-content: space-between;
}
</style>