<template>
  <div class="event-item">
    <p>{{ formatDate }}</p>
    <p>{{ event.type }}</p>

    <div v-if="this.event.type === 'CREATE' || this.event.type === 'UPDATE'">
      <p>
        <b>email:</b> {{ event.payload.email }}
        <b>firstname:</b> {{ event.payload.firstName }}
        <b>lastname:</b> {{ event.payload.lastName }}
        <b>enabled:</b> {{ event.payload.enabled }}
        <b>verified email:</b> {{ event.payload.emailVerified }}
      </p>
    </div>

    <div v-else>
      <p>{{ event.payload }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatDate() {
      return moment(this.event.eventTimestamp).format("DD.MM.yyyy HH:mm:ss")
    }
  }
}
</script>

<style scoped>
.event-item {
  width: 70%;
  border: 1px solid;
}
</style>