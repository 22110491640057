<template>
  <div>
    <form @submit.prevent="search">
      <BaseInput
          label="Kunden id"
          v-model="kundenId"
          type="text"
          placeholder="Kunden Id"
          class="field"
      />
      <BaseButton type="submit">Submit</BaseButton>
    </form>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";

export default {
  components: {BaseButton, BaseInput},
  data() {
    return {
      kundenId: ''
    }
  },
  methods: {
    search() {
      this.$store
          .dispatch('event/fetchEvents', {
            kundenId: this.kundenId,
            token: localStorage.getItem("keycloak-token")
          })
    }
  }
}
</script>

<style scoped>
</style>