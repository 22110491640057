<template>
  <div id="app">
    <AppHeader />
    <SearchButton/>
    <CustomerData/>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import SearchButton from './components/EventSearch.vue'
import CustomerData from "@/components/CustomerData";

export default {
  name: 'App',
  components: {
    CustomerData,
    AppHeader,
    SearchButton
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

button,
label,
input,
optgroup,
select,
textarea {
  display: inline-flex;
  font-family: 'Open sans', sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}
label {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}
</style>
