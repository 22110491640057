<template>
  <div class="customer-data" v-if="customer.kundenId">
    <CustomerDetailsData :customer="customer"/>
    <h3 v-if="events.length > 0">events</h3>
    <h3 v-else>No events</h3>
    <div class="event-list">
      <EventItem :key="event.id" v-for="event in events" :event="event"/>
    </div>
  </div>
</template>

<script>

import EventItem from "@/components/EventItem";
import CustomerDetailsData from "@/components/CustomerDetailsData";

export default {
  components: {
    CustomerDetailsData,
    EventItem
  },
  computed: {
    customer() {
      return this.$store.state.event.customer
    },
    events() {
      return this.$store.state.event.events
    }
  }
}
</script>

<style scoped>
.event-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.customer-data {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto
}
</style>