import axios from 'axios'

const apiClient = axios.create({
    baseURL: `https://sso-customer-historization-dev.private.zooplus.net`,
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 10000
})


export default {
    getEvents(kundenId, token) {
        return apiClient.get('/api/v1/events?kundenId=' + kundenId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}
