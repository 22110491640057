<template>
  <div class="header">
    <div>
      <img alt="zooplus logo" src="../assets/zooplus_logo.png"/>
    </div>
    <h2>Customer Historization</h2>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>